interface EndPointProps {
  initialX: number              // X coordinate of the center of the circle
  initialY: number              // Y coordinate of the center of the circle
  circleRadius: number          // Radius of the circle
  radiusAngle: number           // Angle in degrees
}

/**
 * Calculates the end point of a circle's radius at a specified angle.
 *
 * @param {EndPointProps} props - The input data containing:
 *   - initialX: number - X coordinate of the circle's center.
 *   - initialY: number - Y coordinate of the circle's center.
 *   - circleRadius: number - The radius of the circle.
 *   - radiusAngle: number - The angle in degrees.
 * @returns {{ x: number, y: number }} - The calculated end point coordinates.
 */
export function calculateEndPoint(props: EndPointProps): { x: number, y: number } {
  const { initialX, initialY, circleRadius, radiusAngle } = props

  // Convert angle to radians
  const radiusAngleInRadians = radiusAngle * (Math.PI / 180)

  // Calculate the end point
  const endPointX = initialX + circleRadius * Math.cos(radiusAngleInRadians)
  const endPointY = initialY + circleRadius * Math.sin(radiusAngleInRadians)

  return { x: endPointX, y: endPointY }
}
