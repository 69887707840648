import type { Point } from '@/store/models/kop/utils'

/**
 * Calculates the rotation angle between two points in degrees.
 * @param startPoint - The fixed starting point [x1, y1].
 * @param endPoint - The dynamic end point [x2, y2].
 * @returns The angle between the two points in degrees.
 */
export function calculateRotationAngle(startPoint: Point, endPoint: Point): number {
  const [x1, y1] = startPoint
  const [x2, y2] = endPoint

  // Calculate the angle using atan2
  const angleInRadians = Math.atan2(y2 - y1, x2 - x1)

  // Convert radians to degrees
  return angleInRadians * (180 / Math.PI)
}
