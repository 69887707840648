import axiosIns from '@axios'

const $http = axiosIns

// Branding Work Orders
export const $workOrderSimpleList = async (params: object) => {
  return await $http.get('branding-order-simple-list/', { params })
}

export const $workOrder = async (id: any) => {
  return await $http.get(`branding-orders/${id}/`)
}

export const $getWorkOrderCreateCampaignList = async (params: object) => {
  return await $http.get('branding-orders-create-campaign/', { params })
}

// Approve/reject Branding Line Item
export const $approveLineItem = async (id: number | string, payload: object) => {
  return await $http.patch(`approve-line-item/${id}`, payload)
}

export const $multipleApproveLineItem = async (payload: object) => {
  return await $http.post('multiple-approve-line-item/', payload)
}

export const $multipleUpdateLineItemStatuses = async (payload: object) => {
  return await $http.post('multiple-update-line-item-statuses/', payload)
}

export const $multipleUpdateLineItemAssignee = async (payload: object) => {
  return await $http.post('multiple-update-line-item-assignee/', payload)
}

export const $multipleUpdateLineItemOnHold = async (payload: object) => {
  return await $http.post('multiple-update-line-item-on-hold/', payload)
}

export const $multipleUpdateLineItemPriority = async (payload: object) => {
  return await $http.post('multiple-update-line-item-priority/', payload)
}

export const $multipleUpdateLineItemVisibility = async (payload: object) => {
  return await $http.post('multiple-update-line-item-visibility/', payload)
}

// Branding Line Items
export const $lineItemList = async (params: object) => {
  return await $http.get('branding-items/', { params })
}

export const $lineItem = async (id: any) => {
  return await $http.get(`branding-items/${id}/`)
}

export const $lineItemOptions = async () => {
  return await $http.options('branding-items/')
}

// Branding Line Item Status
export const $getLineItemStatuses = async (params: object) => {
    return await $http.get('branding-item-statuses/', {params})
}

export const $deleteLineItemStatus = async (id: number) => {
    return await $http.delete(`branding-item-statuses/${id}/`)
}

export const $createLineItemStatus = async (payload: object) => {
    return await $http.post('branding-item-statuses/', payload)
}

export const $updateLineItemStatus = async (id: number, payload: object) => {
    return await $http.patch(`branding-item-statuses/${id}/`, payload)
}

export const $lineItemStatusOptions = async () => {
  return await $http.options('branding-item-statuses/')
}

// Branding Line Item Annotations
export const $lineItemAnnotationList = async (params: object) => {
  return await $http.get('branding-item-annotations/', { params: params })
}

export const $lineItemAnnotationCreate = async (payload: object) => {
  return await $http.post('branding-item-annotations/', payload)
}

export const $lineItemAnnotationUpdate = async (id: number, payload: object) => {
  return await $http.patch(`branding-item-annotations/${id}/`, payload)
}

export const $lineItemAnnotationDelete = async (id: number) => {
  return await $http.delete(`branding-item-annotations/${id}/`)
}

// Branding Line Item Annotation Comments
export const $lineItemAnnotationCommentCreate = async (payload: object) => {
  return await $http.post('branding-item-annotation-comments/', payload)
}

export const $lineItemAnnotationCommentDelete = async (id: number) => {
  return await $http.delete(`branding-item-annotation-comments/${id}/`)
}

// Get Next Line Item
export const $getNextPreviousLineItem = async (id: number | string, params: object) => {
  return await $http.get(`get-next-previous-line-item/${id}`, { params: params })
}

// Branding Global Search
export const $brandingGlobalLineItemOptions = async (filters: object) => {
  return await $http.get('branding-global-line-item-options/', { params: filters })
}

// Branding Item Zones
export const $lineItemZoneList = async (params: object) => {
  return await $http.get('line-item-zones/', { params: params })
}

// Branding Item Areas
export const $lineItemAreaList = async (params: object) => {
  return await $http.get('line-item-areas/', { params: params })
}

// Update line item files via Dropbox
export const $updateItemDropboxFiles = async (id: number | string) => {
  return await $http.get(`/update-line-item-files/${id}`)
}

// Branding Line Item status history
export const $getLineItemHistory = async (params: object) => {
  return await $http.get('branding-line-item-history/', { params: params })
}

// Export Excel
export const $exportExcelBrandingItems = async (payload: object) => {
  return await $http.post('/export-excel-branding-items/', payload, { responseType: 'blob' })
}

// Branding Chart
export const $getBrandingChart = async (filters: object) => {
  return await $http.get('/branding-chart/', { params: filters })
}

// Upload Brand Book File for Splitting
export const $uploadBrandBook = async (payload: object) => {
  return await $http.post('/extract-brand-book/', payload, { responseType: 'blob', timeout: 999999 })
}

// Branding Finances
export const $getBrandingFinance = async (filters: object) => {
  return await $http.get('branding-finance/', { params: filters })
}

export const $getNSFinances = async (filters: object) => {
  return await $http.get('ns-finances/', { params: filters })
}

