import { v4 as uuidv4 } from 'uuid'
import { updateKopItem } from '@/store/helpers/utils/kop/updateKopItem'

const circleData = (id: string, x: number, y: number, isEditKopItemTypeShape: boolean = false, initialZoom: number = 1) => {
  const initialData = {
    type: 'circle',
    id: id,
    x: x,
    y: y,
    radius: 5,
    stroke: '#000',
    fill: null,
    strokeWidth: 2,
    opacity: 1,
    draggable: true
  }
  // KOP item type shape data config
  const editKopItemTypeShapeData = !isEditKopItemTypeShape ? {} : {
    // Adjust the Scale X taking into account KOP Editor Initial scale
    scaleX: 1 / initialZoom,
    // Adjust the Scale Y taking into account KOP Editor Initial scale
    scaleY: 1 / initialZoom
  }
  return {
    ...initialData,
    ...editKopItemTypeShapeData
  }
}

const rectData = (id: string, x: number, y: number, isEditKopItemTypeShape: boolean = false, initialZoom: number = 1) => {
  const initialData = {
    type: 'rect',
    id: id,
    x: x,
    y: y,
    width: 2,
    height: 2,
    stroke: '#000',
    fill: null,
    opacity: 1,
    strokeWidth: 2,
    draggable: true
  }
  // KOP item type shape data config
  const editKopItemTypeShapeData = !isEditKopItemTypeShape ? {} : {
    // Adjust the Scale X taking into account KOP Editor Initial scale
    scaleX: 1 / initialZoom,
    // Adjust the Scale Y taking into account KOP Editor Initial scale
    scaleY: 1 / initialZoom
  }
  return {
    ...initialData,
    ...editKopItemTypeShapeData
  }
}

const lineData = (id: string, x: number, y: number, isEditKopItemTypeShape: boolean = false, initialZoom: number = 1) => {
  const initialData = {
    type: 'line',
    id,
    points: [x, y],
    stroke: '#000',
    strokeWidth: 2,
    hitStrokeWidth: 10,
    lineCap: 'round',
    opacity: 1,
    draggable: true
  }
  // KOP item type shape data config
  const editKopItemTypeShapeData = !isEditKopItemTypeShape ? {} : {
    // Adjust the points taking into account KOP Editor Initial scale
    points: [x * initialZoom, y * initialZoom],
    // Adjust the Scale X taking into account KOP Editor Initial scale
    scaleX: 1 / initialZoom,
    // Adjust the Scale Y taking into account KOP Editor Initial scale
    scaleY: 1 / initialZoom
  }
  return {
    ...initialData,
    ...editKopItemTypeShapeData
  }
}

const textData = (id: string, x: number, y: number, isEditKopItemTypeShape: boolean = false, initialZoom: number = 1) => {
  // Base offsets for the textData element
  const baseXOffset = 12
  const baseYOffset = 7

  const initialData = {
    type: 'text', // Specifies that this is a text element
    id: id, // Unique identifier
    x: x - baseXOffset, // x-coordinate
    y: y - baseYOffset, // y-coordinate
    text: 'Text', // Default text content
    stroke: '#000', // Text color (default: black)
    fill: null, // Background color (default: transparent)
    fontSize: 12, // Font size
    padding: 2, // Padding
    align: 'center', // Horizontal alignment of the text (default: center)
    verticalAlign: 'middle', // Vertical alignment of the text (default: middle)
    opacity: 1, // Opacity
    draggable: true, // Draggable property
  }
  // KOP item type shape data config
  const editKopItemTypeShapeData = !isEditKopItemTypeShape ? {} : {
    // Adjust the x position to account for the base offset and scale it according to the initial zoom level
    x: x - (baseXOffset / initialZoom),
    // Adjust the y position to account for the base offset and scale it according to the initial zoom level
    y: y - (baseYOffset / initialZoom),
    // Adjust the Scale X taking into account KOP Editor Initial scale
    scaleX: 1 / initialZoom,
    // Adjust the Scale Y taking into account KOP Editor Initial scale
    scaleY: 1 / initialZoom
  }
  return {
    ...initialData,
    ...editKopItemTypeShapeData
  }
}

export const addDrawingItem = (
  selectedBtn: string,
  x: number,
  y: number,
  isEditKopItemTypeShape: boolean = false,
  initialZoom: number = 1
) => {
  let drawingItem
  switch (selectedBtn) {
    case 'circle':
      const circle = circleData(uuidv4(), x, y, isEditKopItemTypeShape, initialZoom)
      drawingItem = circle
      break
    case 'rect':
      const rect = rectData(uuidv4(), x, y, isEditKopItemTypeShape, initialZoom)
      drawingItem = rect
      break
    case 'line':
    case 'pencil':
      const line = lineData(uuidv4(), x, y, isEditKopItemTypeShape, initialZoom)
      drawingItem = line
      break
    case 'text':
      // Centered text
      const text = textData(uuidv4(), x, y, isEditKopItemTypeShape, initialZoom)
      drawingItem = text
      break
    default:
      break
  }
  return drawingItem
}

// Function to handle updating offline items
const updateOfflineItems = (offlineMode: boolean, offlineItems: any[], updatedItem: any, drawingItem: any) => {
  if (!offlineMode) return offlineItems

  return offlineItems.map(i => {
    if (i.id === drawingItem.id) {
      return { ...i, ...updatedItem }
    }
    return i
  })
}

// Func of updating a drawing item immediately after adding a drawing item
export const updateDrawingItem = (
  selectedBtn: string,
  drawingItem: any,
  x: number,
  y: number,
  offlineMode: boolean = false,
  offlineItems: any[] = [],
  isEditKopItemTypeShape: boolean = false,
  initialZoom: number = 1,
  stage: any
) => {
  let updatedItem = { ...drawingItem }
  // Determine the zoom factor - apply only if Editing KOP Item Type Shape
  const kopEditorZoom = isEditKopItemTypeShape ? initialZoom : 1

  // Function to apply zoom to coordinates
  const applyZoom = (value: number) => value * kopEditorZoom

  // Function to update line item
  const updateLine = (item: any, x: number, y: number) => {
    item.points = item.points.slice(0, 2).concat([applyZoom(x), applyZoom(y)])
  }

  // Function to update pencil item
  const updatePencil = (item: any, x: number, y: number) => {
    item.points = [...item.points, applyZoom(x), applyZoom(y)]
  }

  // Function to update rectangle item
  const updateRect = (item: any, x: number, y: number) => {
    item.width = applyZoom(x) - applyZoom(item.x)
    item.height = applyZoom(y) - applyZoom(item.y)
  }

  // Function to update circle item
  const updateCircle = (item: any, x: number, y: number) => {
    const dx = applyZoom(x) - applyZoom(item.x)
    const dy = applyZoom(y) - applyZoom(item.y)
    item.radius = Math.sqrt(dx * dx + dy * dy)
  }

  // Update the drawing item based on the selected button
  switch (selectedBtn) {
    case 'line':
      updateLine(updatedItem, x, y)
      break
    case 'pencil':
      updatePencil(updatedItem, x, y)
      break
    case 'rect':
      updateRect(updatedItem, x, y)
      break
    case 'circle':
      updateCircle(updatedItem, x, y)
      break
    case 'text':
      // No update logic for text
      break
    case 'kop':
      updateKopItem(updatedItem, x, y, stage)
      break
    default:
      // No update logic for unknown item types
      break
  }

  // Update offline items if necessary
  const updatedOfflineItems = updateOfflineItems(offlineMode, offlineItems, updatedItem, drawingItem)

  return { updatedItem, updatedOfflineItems }
}
