import type { Point } from '@/store/models/kop/utils'

/**
 * Converts two points into a rectangle's corner points.
 * @param startPoint - The fixed starting point [x1, y1].
 * @param endPoint - The dynamic end point [x2, y2].
 * @param width - The desired width of the rectangle.
 * @returns A flat array of the rectangle's four corners [topLeft, topRight, bottomRight, bottomLeft].
 */
 export function pointsToRectangle(startPoint: Point, endPoint: Point, width: number): number[] {
  const halfWidth = width / 2
  const [x1, y1] = startPoint
  const [x2, y2] = endPoint

  // Calculate direction vector (dx, dy)
  const dx = x2 - x1
  const dy = y2 - y1

  // Handle edge case: zero-length line
  const length = Math.sqrt(dx * dx + dy * dy) || 1 // Prevent division by zero

  // Calculate normal vector (perpendicular to direction vector)
  const nx = (dy / length) * halfWidth
  const ny = (-dx / length) * halfWidth

  // Calculate rectangle points (clockwise order)
  const topLeft: Point = [x1 - nx, y1 - ny]
  const topRight: Point = [x2 - nx, y2 - ny]
  const bottomRight: Point = [x2 + nx, y2 + ny]
  const bottomLeft: Point = [x1 + nx, y1 + ny]

  // Return points as a flat array
  return [...topLeft, ...topRight, ...bottomRight, ...bottomLeft]
}
