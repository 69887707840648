import type { Point } from '@/store/models/kop/utils'
import { pointsToRectangle } from '@/store/helpers/utils/kop/pointsToRectangle'
import { calculateRotationAngle } from '@/store/helpers/utils/kop/calculateRotationAngle'
import { calculateEndPoint } from '@/store/helpers/utils/kop/calculateEndPoint'
import { Stage as KonvaStage } from 'konva/lib/Stage'

/**
 * Updates the item's points based on a new position for the second point, ensuring the rectangle width stays consistent.
 * @param item - The object containing the initial points and rectangle points.
 * @param x - The new x-coordinate for the second point.
 * @param y - The new y-coordinate for the second point.
 * @param stage - The Konva stage instance for coordinate transformations.
 */
export function updateKopItem(item: any, x: number, y: number, stage: KonvaStage): void {
  if (!item.points || item.points.length < 2) {
    console.error('Invalid item points. Must contain at least two values (initial point and second point).')
    return
  }

  // Extract the fixed initial point (first two values in item.points)
  const initialPoint: Point = [item.initial_points[0], item.initial_points[1]]

  // Calculate the rectangle points using the initial point and the dynamic second point
  const rectanglePoints = pointsToRectangle(initialPoint, [x, y], item?.kop_item_shape_height || 0)

  // Update the item's points with the new rectangle corners
  item.points = rectanglePoints

  // Calculate the rotation angle of the rectangle
  const rotationAngle = calculateRotationAngle(initialPoint, [x, y])

  // Update the fillPatternRotation to align with the rectangle
  item.fillPatternRotation = rotationAngle

  // Set the pattern image as repeating horizontally (repeat-x)
  item.fillPatternRepeat = 'repeat-x'  // Ensure the pattern repeats horizontally

  // fillPatternOffset: { x: -220, y: 70 }

  item.fillPatternOffset = { x: 0, y: 0 }

  const { x: itemX, y: itemY } = calculateEndPoint({
    initialX: initialPoint[0],
    initialY: initialPoint[1],
    circleRadius: item.kop_item_shape_height / 2,
    radiusAngle: rotationAngle - 90
  })

  // Update pattern image's X position based on the width
  item.fillPatternX = itemX // Position the pattern on the left side of the rectangle

  // Set pattern image's Y position based on the height of the rectangle (fixed height)
  item.fillPatternY = itemY // Position the pattern at the top side of the rectangle

  // Re-draw or update your Konva layer to reflect changes
  stage.batchDraw()
}
